import type { Utente } from '@apb/database'
import { TBoardMetadata } from '@apb/database/BoardData'
import { useRouter } from 'next/router'
import { Suspense, useState } from 'react'
import useOpenPopup from 'use-open-popup'

import UsersTable from '../containers/UsersTable'
import useSearch from '../hooks/useSearch'
import AggiungiUtenteCard from './AggiungiUtenteCard'
import Card from './Card'
import SearchInput from './SearchInput'

const searchOpts = { keys: ['user.nome', 'user.cognome', 'dipartimento'] }
export default function LineManagersTableCard({
  lineManagers,
  aziendaId,
  mutate
}: {
  lineManagers: (Pick<
    Utente,
    'nome' | 'cognome' | 'id' | 'email' | 'dipartimento' | 'emailSent' | 'aziendaId' | 'role' | 'path' | 'parentId'
  > & {
    boardMetadata: TBoardMetadata
  })[]
  aziendaId: number
  mutate: () => void
}): JSX.Element {
  const [query, setQuery] = useState<string>('')
  const results = useSearch(lineManagers, query, searchOpts)

  const router = useRouter()

  const openPopup = useOpenPopup(({ handleClose }) => (
    <Suspense fallback={<div tabIndex={0}> </div>}>
      <AggiungiUtenteCard
        parentId={null}
        role="LINE_MANAGER"
        aziendaId={aziendaId}
        handleClose={() => {
          mutate()
          handleClose()
        }}
      />
    </Suspense>
  ))

  return (
    <Card className="overflow-hidden">
      <div className="flex gap-3 flex-row mb-2 whitespace-nowrap">
        <h3 className="flex-grow m-0">
          Line Managers <span className="text-sm text-gray-400 font-medium">({lineManagers.length})</span>
        </h3>
        <SearchInput query={query} setQuery={setQuery} />
        <button className="btn-primary" onClick={openPopup}>
          <i className="fas fa-plus" /> Aggiungi
        </button>
      </div>
      {lineManagers.length > 0 ? (
        results.length ? (
          <UsersTable
            utenti={results}
            mutate={mutate}
            showBoard
            onRowClick={lm => {
              router.push(`/lineManager/${lm.id}`)
            }}
          />
        ) : (
          <p className="text-center m-1 mt-3">
            Nessun risultato.{' '}
            <a
              onClick={() => {
                setQuery('')
              }}
            >
              Annulla la ricerca
            </a>
          </p>
        )
      ) : (
        <p className="text-center m-1 mt-3">
          <a onClick={openPopup}>Aggiungi</a> un line manager all&apos;azienda
        </p>
      )}
    </Card>
  )
}
