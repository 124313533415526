import type { Utente } from '@apb/database'
import { TBoardMetadata } from '@apb/database/BoardData'
import { AziendaAdminType } from '@apb/shared/const'
import { Suspense, useState } from 'react'
import useOpenPopup from 'use-open-popup'

import UsersTable from '../containers/UsersTable'
import useSearch from '../hooks/useSearch'
import AggiungiUtenteCard from './AggiungiUtenteCard'
import Card from './Card'
import SearchInput from './SearchInput'

const searchOpts = { keys: ['user.nome', 'user.cognome', 'dipartimento'] }
export default function AziendaAdminsTableCard({
  aziendaAdmins,
  aziendaId,
  mutate,
  aziendaAdminType,
  disableEditing
}: {
  aziendaAdmins: (Pick<
    Utente,
    'nome' | 'cognome' | 'id' | 'email' | 'dipartimento' | 'emailSent' | 'aziendaId' | 'role' | 'path' | 'parentId'
  > & {
    boardMetadata: TBoardMetadata
  })[]
  aziendaId: number
  mutate: () => void
  aziendaAdminType: AziendaAdminType
  disableEditing?: boolean
}): JSX.Element {
  const [query, setQuery] = useState<string>('')
  const results = useSearch(aziendaAdmins, query, searchOpts)

  const openPopup = useOpenPopup(({ handleClose }) => (
    <Suspense fallback={<div tabIndex={0}> </div>}>
      <AggiungiUtenteCard
        parentId={null}
        role={aziendaAdminType === AziendaAdminType.HR ? 'HR' : 'DIREZIONE'}
        aziendaId={aziendaId}
        handleClose={() => {
          mutate()
          handleClose()
        }}
      />
    </Suspense>
  ))

  return (
    <Card className="overflow-hidden mb-5">
      <div className="flex gap-3 flex-row mb-2 whitespace-nowrap">
        <h3 className="flex-grow m-0">
          {{ DIREZIONE: 'Direzione', HR: 'Risorse umane' }[aziendaAdminType]}{' '}
          <span className="text-sm text-gray-400 font-medium">({aziendaAdmins.length})</span>
        </h3>
        <SearchInput query={query} setQuery={setQuery} />
        {!disableEditing && (
          <button className="btn-primary" onClick={openPopup}>
            <i className="fas fa-plus" /> Aggiungi
          </button>
        )}
      </div>
      {aziendaAdmins.length > 0 ? (
        results.length > 0 ? (
          <UsersTable utenti={results} mutate={mutate} showBoard />
        ) : (
          <p className="text-center m-1 mt-3">
            Nessun risultato.{' '}
            <a
              onClick={() => {
                setQuery('')
              }}
            >
              Annulla la ricerca
            </a>
          </p>
        )
      ) : (
        <p className="text-center m-1 mt-3">
          <a onClick={openPopup}>Aggiungi</a> utenti{' '}
          {{ DIREZIONE: 'alla direzione', HR: 'alle risorse umane' }[aziendaAdminType]}
        </p>
      )}
    </Card>
  )
}
