import type { Utente } from '@apb/database'
import { TBoardMetadata } from '@apb/database/BoardData'
import { Suspense } from 'react'
import useOpenPopup from 'use-open-popup'

import UsersTable from '../containers/UsersTable'
import AggiungiUtenteCard from './AggiungiUtenteCard'
import Card from './Card'

export default function DirezioneGeneraleTableCard({
  direttoreGenerale,
  aziendaId,
  mutate,
  disableEditing
}: {
  direttoreGenerale:
    | (Pick<
        Utente,
        'nome' | 'cognome' | 'id' | 'email' | 'dipartimento' | 'emailSent' | 'aziendaId' | 'role' | 'path' | 'parentId'
      > & {
        boardMetadata: TBoardMetadata
      })
    | null
  aziendaId: number
  mutate: () => void
  disableEditing?: boolean
}): JSX.Element {
  const openPopup = useOpenPopup(({ handleClose }) => (
    <Suspense fallback={<div tabIndex={0}> </div>}>
      <AggiungiUtenteCard
        parentId={null}
        role={'DIREZIONE_GENERALE'}
        aziendaId={aziendaId}
        singleUser
        handleClose={() => {
          mutate()
          handleClose()
        }}
      />
    </Suspense>
  ))

  return (
    <Card className="overflow-hidden mb-5">
      <div className="flex gap-3 flex-row mb-2 whitespace-nowrap">
        <h3 className="flex-grow m-0">Direttore generale</h3>
        {!disableEditing && direttoreGenerale === null && (
          <button className="btn-primary" onClick={openPopup}>
            <i className="fas fa-plus" /> Aggiungi
          </button>
        )}
      </div>
      {direttoreGenerale ? (
        <UsersTable utenti={[direttoreGenerale]} mutate={mutate} showBoard hideDipartimento />
      ) : (
        <p className="text-center m-1 mt-3">
          <a onClick={openPopup}>Aggiungi</a> un direttore generale
        </p>
      )}
    </Card>
  )
}
