import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import Card, { PopupCardHeader } from '../components/Card'
import ErrorField from '../components/ErrorField'
import Spinner from '../components/Spinner'

type FormData = { nome: string }

export default function AziendaDetailsCard({
  onSubmit,
  editData,
  handleClose
}: {
  onSubmit: (data: FormData) => Promise<void>
  editData?: FormData
  handleClose: () => void
}): JSX.Element {
  return (
    <Card className="flex-shrink max-w-lg" role="dialog" aria-modal="true">
      <PopupCardHeader className="mb-2" handleClose={handleClose}>
        {editData ? 'Modifica' : 'Crea'} azienda
      </PopupCardHeader>
      <Formik<FormData>
        initialValues={editData ?? { nome: '' }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          nome: Yup.string().min(2, 'Troppo corto').required('È richiesto')
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorField name="nome" label="Nome" className="w-full" type="text" placeholder="Nome..." />
            <div className="text-center">
              <button type="submit" className="btn-success">
                {isSubmitting && <Spinner className="mr-2" />}
                {editData ? 'Modifica' : 'Crea'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
