import { AziendaAdminType } from '@apb/shared/const'
import classNames from 'classnames'
import moment from 'moment'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { z } from 'zod'

import AziendaAdminsTableCard from '../components/AziendaAdminsTableCard'
import AziendaImage from '../components/AziendaImage'
import LineManagersTableCard from '../components/LineManagersTableCard'
import { trpc } from '../trpc'
import TreeView from './TreeView'
import { SetBreadcrumbs } from './Breadcrumbs'
import { useIsAdmin } from '../hooks/useHasRole'
import { Azienda } from '@apb/database/schema'
import useOpenPopup from 'use-open-popup'
import { DeleteAziendaPopup } from './AziendeView'
import AziendaDetailsCard from './AziendaDetailsCard'
import ProtectedView from './ProtectedView'
import DirezioneGeneraleTableCard from '../components/DirezioneGeneraleTableCard'

function AziendeOptionsButtons({ refetch, azienda }: { refetch: () => void; azienda: Pick<Azienda, 'id' | 'nome'> }) {
  const router = useRouter()
  const { mutateAsync: mutateDelete } = trpc.aziende.delete.useMutation({
    onSuccess() {
      router.back()
    }
  })

  const { mutateAsync: mutateUpdate } = trpc.aziende.update.useMutation({
    onSettled: refetch
  })

  const openDelete = useOpenPopup(
    useCallback(
      ({ handleClose }: { handleClose: () => void }) => (
        <DeleteAziendaPopup
          handleClose={handleClose}
          // azienda={azienda}
          onSubmit={async () => {
            await mutateDelete({ aziendaId: azienda.id })
            handleClose()
          }}
        />
      ),
      [azienda, mutateDelete]
    )
  )

  const openEdit = useOpenPopup(
    useCallback(
      ({ handleClose }: { handleClose: () => void }) => (
        <AziendaDetailsCard
          handleClose={handleClose}
          onSubmit={async data => {
            await mutateUpdate({ aziendaId: azienda.id, data })
            handleClose()
          }}
          editData={azienda}
        />
      ),
      [azienda, mutateUpdate]
    )
  )

  return (
    <div className="undertitle-buttons">
      <ProtectedView keys={['ADMIN', 'HR', 'DIREZIONE', 'DIREZIONE_GENERALE']}>
        <button onClick={openEdit} className="btn">
          <i className="fas fa-edit" />
          <span className="btn-label">Modifica</span>
        </button>
      </ProtectedView>
      <ProtectedView keys={['ADMIN']}>
        <>
          <button onClick={openDelete} className="btn btn-danger">
            <i className="far fa-trash-alt" />
            <span className="btn-label">Elimina</span>
          </button>
        </>
      </ProtectedView>
    </div>
  )
}

const zodViewType = z.enum(['s', 't']).default('s')
type View = z.infer<typeof zodViewType>

export default function AziendaView({ aziendaId }: { aziendaId: number }) {
  const router = useRouter()

  const selectedView = useMemo(() => zodViewType.parse(router.query.v), [router.query.v])
  const setView = useCallback(
    (v: View) => {
      router.push({ query: { ...router.query, v } })
    },
    [router]
  )

  const [data, { refetch }] = trpc.aziende.get.useSuspenseQuery({ aziendaId, withDeepUtenti: selectedView === 't' })

  const canGoBack = useIsAdmin()

  if (!data) return null

  return (
    <div>
      <Head>
        <title>{data.nome} - Agile Personal Board</title>
      </Head>

      <SetBreadcrumbs
        crumbs={[
          { label: 'Home', href: '/' },
          ...(canGoBack ? [{ label: 'Aziende', href: '/aziende' }] : []),
          { label: data.nome, href: `/azienda/${data.id}` }
        ]}
      />

      <div className="p-4 pb-5 flex container-std">
        <div className="flex-grow">
          <h2 className="mb-0">{data.nome}</h2>
          {/* {isAdmin && <p className="text-gray-400 font-medium">Creata il {moment(data.createdAt).format('LL')}</p>} */}
          <p className="text-gray-400 font-medium mb-2">{moment().format('LL')}</p>
          <AziendeOptionsButtons azienda={data} refetch={refetch} />
        </div>
        <AziendaImage aziendaId={aziendaId} />
      </div>

      <div className="text-right mb-3 container-std">
        <span className="text-sm text-gray-500 mr-2">Vista:</span>
        <button
          title="Separata"
          className={classNames(
            'btn-icon-sm hover:bg-gray-200 p-2 pl-3 rounded-r-none border-2 hover:border-gray-300',
            selectedView === 's' && 'bg-gray-200'
          )}
          onClick={() => setView('s')}
        >
          <i className="fas fa-columns" />
        </button>
        {/* <button
          title="Combinata"
          className={classNames(
            'btn-icon-sm hover:bg-gray-200 p-2 rounded-none border-2 border-x-transparent hover:border-gray-300',
            selectedView === 'COMBINED' && 'bg-gray-200'
          )}
          onClick={() => setView('COMBINED')}
        >
          <i className="fas fa-table" />
        </button> */}
        <button
          title="Ad albero"
          className={classNames(
            'btn-icon-sm hover:bg-gray-200 p-2 pr-3 rounded-l-none border-2 hover:border-gray-300',
            selectedView === 't' && 'bg-gray-200'
          )}
          onClick={() => setView('t')}
        >
          <i className="fas fa-sitemap" />
        </button>
      </div>

      {selectedView === 's' && (
        <div className="container-std">
          <DirezioneGeneraleTableCard
            direttoreGenerale={data.direttoreGenerale ? { ...data.direttoreGenerale, aziendaId } : null}
            aziendaId={aziendaId}
            mutate={refetch}
          />

          <AziendaAdminsTableCard
            aziendaAdminType={AziendaAdminType.Direzione}
            aziendaAdmins={data.direzione?.map(o => ({ ...o, aziendaId })) ?? []}
            aziendaId={aziendaId}
            mutate={refetch}
          />

          <AziendaAdminsTableCard
            aziendaAdminType={AziendaAdminType.HR}
            aziendaAdmins={data.hr?.map(o => ({ ...o, aziendaId })) ?? []}
            aziendaId={aziendaId}
            mutate={refetch}
          />

          <LineManagersTableCard
            lineManagers={data.lineManagers?.map(o => ({ ...o, aziendaId })) ?? []}
            aziendaId={aziendaId}
            mutate={refetch}
          />
        </div>
      )}

      {selectedView === 't' && (
        <div className="overflow-x-auto">
          <TreeView
            utenti={[
              ...data.direzione!.map(o => ({ ...o, aziendaId })),
              ...data.hr!.map(o => ({ ...o, aziendaId })),
              ...data.lineManagers!.map(o => ({ ...o, aziendaId })),
              ...data.deepUtenti!.map(o => ({ ...o, aziendaId }))
            ]}
            rootUser={data.direttoreGenerale ? { ...data.direttoreGenerale, aziendaId } : undefined}
            refetch={refetch}
          />
        </div>
      )}
    </div>
  )
}
