import type { Azienda } from '@apb/database'
import moment from 'moment'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import useOpenPopup from 'use-open-popup'

import Card, { PopupCardHeader } from '../components/Card'
import SearchInput from '../components/SearchInput'
import Table from '../components/Table'
import AziendaDetailsCard from '../containers/AziendaDetailsCard'
import useSearch from '../hooks/useSearch'
import { trpc } from '../trpc'
import EllipsisMenu from './EllipsisMenu'

export function DeleteAziendaPopup({
  // azienda,
  handleClose,
  onSubmit
}: {
  // azienda: Pick<Azienda, 'id'>
  handleClose: () => void
  onSubmit: () => void
}) {
  return (
    <Card className="max-w-sm" role="dialog" aria-modal="true">
      <PopupCardHeader className="mb-2" handleClose={handleClose}>
        Sei sicuro?
      </PopupCardHeader>
      <p>L&apos;azienda sarà eliminata permanentemente, assieme a tutti i dati che contiene.</p>
      <div className="text-center mt-2">
        <button
          className="btn-text mr-3"
          onClick={() => {
            handleClose()
          }}
        >
          Annulla
        </button>
        <button
          className="btn-danger"
          onClick={() => {
            onSubmit()
          }}
        >
          Elimina
        </button>
      </div>
    </Card>
  )
}

function AziendaOptions({ azienda }: { azienda: Pick<Azienda, 'id' | 'nome' | 'status'> }): JSX.Element {
  const ctx = trpc.useContext()

  const { mutateAsync: mutateUpdateAzienda } = trpc.aziende.update.useMutation({
    onSuccess() {
      ctx.aziende.list.refetch({})
    }
  })

  const { mutateAsync: mutateDeleteAzienda } = trpc.aziende.delete.useMutation({
    onSuccess() {
      ctx.aziende.list.refetch({})
    }
  })

  const openEdit = useOpenPopup(
    useCallback(
      ({ handleClose }: { handleClose: () => void }) => (
        <AziendaDetailsCard
          handleClose={handleClose}
          onSubmit={async data => {
            await mutateUpdateAzienda({ aziendaId: azienda.id, data })
            handleClose()
          }}
          editData={azienda}
        />
      ),
      [azienda, mutateUpdateAzienda]
    )
  )

  const openDelete = useOpenPopup(
    useCallback(
      ({ handleClose }: { handleClose: () => void }) => (
        <DeleteAziendaPopup
          // azienda={azienda}
          handleClose={handleClose}
          onSubmit={async () => {
            await mutateDeleteAzienda({ aziendaId: azienda.id })
            handleClose()
          }}
        />
      ),
      [azienda, mutateDeleteAzienda]
    )
  )

  return (
    <EllipsisMenu>
      <Card className="!m-0 !rounded-lg !p-1 flex flex-col">
        {/* <button
          onClick={() => {
            mutateUpdateAzienda({ aziendaId: azienda.id.toString(), data: { status: AziendaStatus.Archiviato } })
          }}
          className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
        >
          <i className="fas fa-archive w-4 mr-2" />
          Archivia
        </button> */}
        <button
          className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
          onClick={openEdit}
        >
          <i className="fas fa-edit w-4 mr-2" />
          Modifica
        </button>
        <button
          className="w-full transition hover:bg-gray-100 text-gray-500 hover:text-gray-900 text-left"
          onClick={openDelete}
        >
          <i className="far fa-trash-alt w-4 mr-2" />
          Elimina
        </button>
      </Card>
    </EllipsisMenu>
  )
}

const searchOpts = { keys: ['nome'] }
export default function AziendeView(): JSX.Element {
  const [aziende, { refetch }] = trpc.aziende.list.useSuspenseQuery({})

  const [query, setQuery] = useState<string>('')
  const results = useSearch(aziende || [], query, searchOpts)

  const { mutateAsync: mutateCreateAzienda } = trpc.aziende.create.useMutation({ onSuccess: () => refetch() })
  const router = useRouter()

  const openPopup = useOpenPopup(({ handleClose }) => {
    return (
      <AziendaDetailsCard
        handleClose={handleClose}
        onSubmit={async azienda => {
          await mutateCreateAzienda(azienda)
          refetch()
          handleClose()
        }}
      />
    )
  })

  return (
    <Card>
      <Head>
        <title>Aziende - Agile Personal Board</title>
      </Head>
      <div className="flex gap-3 flex-row mb-2 whitespace-nowrap">
        <h3 className="flex-grow m-0">
          Aziende <span className="text-sm text-gray-400 font-medium">({results.length})</span>
        </h3>
        <SearchInput query={query} setQuery={setQuery} />
        <button className="btn-primary" onClick={openPopup}>
          <i className="fas fa-plus" /> Aggiungi
        </button>
      </div>
      {aziende && aziende.length > 0 ? (
        results.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Creato il</th>
                <th className="w-0"></th>
              </tr>
            </thead>
            <tbody>
              {results.map(a => (
                <tr
                  key={a.id}
                  className="cursor-pointer"
                  onClick={() => {
                    router.push(`/aziende/${a.id}`)
                  }}
                >
                  <td className="font-medium">{a.nome}</td>
                  <td>{moment(a.createdAt).format('LL')}</td>
                  <td>
                    <AziendaOptions azienda={a} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="text-center m-1 mt-3">
            Nessun risultato.{' '}
            <a
              onClick={() => {
                setQuery('')
              }}
            >
              Annulla la ricerca
            </a>
          </p>
        )
      ) : (
        <p className="text-center m-1 mt-3">
          <a onClick={openPopup}>Aggiungi</a> un azienda
        </p>
      )}
    </Card>
  )
}
